import { useEffect } from 'react';
import { /*useLocation,*/ NavLink } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { ReactComponent as LogoDark } from '../../assets/icon-miso.min.svg';
// import { ReactComponent as LogoLight } from '../../assets/icon-hypergiant-white.min.svg';
import Nav from '../../components/Nav';
// import Footer from '../../components/Footer';
import classNames from '../../utils/classNames';
import scrollToTop from '../../utils/scrollToTop';
import styles from './index.module.scss';
import getConfig from '../../config';

const LogoLight = LogoDark; // Temp until dark logo replacement

const { BRANDING, TAGLINE } = getConfig();

smoothscroll.polyfill();

const AppLogo = ({ logo, onClick }) => (
  <div className={styles.Logo} aria-label="logo" title={`${BRANDING} :: ${TAGLINE}`}>
    <NavLink to="/">
      {logo === 'dark' ? <LogoDark onClick={onClick} /> : <LogoLight onClick={onClick} />}
    </NavLink>
  </div>
);

/** Main - Layout Component */
export default function Main({ className, title = '', logo = 'light', basic, children }) {
  return (
    <>
      <Nav />
      <AppLogo logo={logo} onClick={() => scrollToTop()} />
      <main className={classNames(styles.Main, className)} title={title}>
        <section title="">{children}</section>
      </main>
      {/* {basic ? null : <Footer />} */}
    </>
  );
}

export { AppLogo };
